<template>
  <div class="box_1">
    <div class="header">
      <div class="text" @click="goToMenuPage()">巴林右旗订单页面</div> 
    </div>

    <div class="content_box_order">
      <!-- 1 -->
      <div class="content_item">
        <div class="order_box">
          <div class="title"><span class="main_color">牲畜订单 {{ shengChuList.length }}</span></div>

          <div class="list_box ph_10">
            <div class="list_item color_green" v-for="(item, index) in shengChuList" :key="index">
              <div>
                <span>耳标：<span class="color_ff2c64 font_number">{{ item.ear_num }}</span> </span>
                <span class="ml_30">品种：<span class="color_ff2c64 font_number">{{ item.ear_num }}</span> </span>
                <span class="ml_30">价格：<span class="color_ff2c64 font_number">{{ item.price }}</span> </span>
              </div>

              <div class="mt_5">
                <span>产地：<span class="color_ff2c64">{{ item.addr1 }}</span> </span>
              </div>
              <div class="mt_5">
                <span>售卖地：<span class="color_ff2c64">{{ item.addr2 }}</span> </span>
              </div>
            </div>
          </div>
        </div>

        <div class="order_box">
          <div class="title"><span class="main_color">机械订单 {{ maShineList.length }}</span></div>

          <div class="list_box ph_10">
            <div class="list_item color_green" v-for="(item, index) in maShineList" :key="index">
              <div>
                <span>名称：<span class="color_3372e2">{{ item.name }}</span> </span>
                <span class="ml_30">使用年限：<span class="color_3372e2">{{ item.use_year }}</span> </span>
                <span class="ml_30">价格：<span class="color_3372e2 font_number">{{ item.price }}</span> </span>
              </div>

              <div class="mt_5">
                <span>产地：<span class="color_3372e2">{{ item.addr1 }}</span> </span>
              </div>
              <div class="mt_5">
                <span>售卖地：<span class="color_3372e2">{{ item.addr2 }}</span> </span>
              </div>
            </div>
          </div>
        </div>
      </div> 

      <!-- 2 -->
      <div class="content_item">
        <div class="order_box">
          <div class="title"><span class="main_color">草料订单 {{ caoLiaoList.length }}</span></div>

          <div class="list_box ph_10">
            <div class="list_item color_green" v-for="(item, index) in caoLiaoList" :key="index">
              <div>
                <span>名称：<span class="color_orange">{{ item.name }}</span> </span>
                <span class="ml_30">购买量：<span class="color_orange font_number">{{ item.unit }}</span> </span>
                <span class="ml_30">价格：<span class="color_orange font_number">{{ item.price }}</span> </span>
              </div>

              <div class="mt_5">
                <span>产地：<span class="color_orange">{{ item.addr1 }}</span> </span>
              </div>
              <div class="mt_5">
                <span>售卖地：<span class="color_orange">{{ item.addr2 }}</span> </span>
              </div>
            </div>
          </div>
        </div>

        <div class="order_box">
          <div class="title"><span class="main_color">农用地订单 {{ nongYongDiList.length }}</span></div>

          <div class="list_box ph_10">
            <div class="list_item color_green" v-for="(item, index) in nongYongDiList" :key="index">
              <div>
                <span>编号：<span class="color_fff">{{ item.name }}</span> </span>
                <span class="ml_30">面积：<span class="color_fff font_number">{{ item.unit }}</span> </span>
                <span class="ml_30">价格：<span class="color_fff font_number">{{ item.price }}</span> </span>
              </div>

              <div class="mt_5">
                <span>购买年限：<span class="color_fff">{{ item.addr1 }}</span> </span>
              </div>
              <div class="mt_5">
                <span>售卖地：<span class="color_fff">{{ item.addr2 }}</span> </span>
              </div>
            </div>
          </div>
        </div>
      </div> 
      
    </div>
  </div>
</template>

<script>
  import BaLinYouQiMap from '../maps/baLinYouQiMap.vue';

  export default {
    name: 'showPage1',
    components: { BaLinYouQiMap },
    data() {
      return {
        activeIndex: 0,
        starIcon: require('../../../../assets/images/ba-lin-you-qi/star.svg'),
        data1: {},
        data2: {},

        shengChuList: [
          {
            ear_num: '10021',
            type: '西门塔尔牛',
            price: '￥18000.00',
            addr1: '大板镇大冷村',
            addr2: '索博日嘎镇索博日嘎嘎查',
          },
          {
            ear_num: '12784',
            type: '西门塔尔牛',
            price: '￥19000.00',
            addr1: '大板镇西哈嘎查',
            addr2: '索博日嘎镇索博日嘎嘎查',
          },
          {
            ear_num: '82763',
            type: '奶牛',
            price: '￥12500.00',
            addr1: '大板镇西哈嘎查',
            addr2: '索博日嘎镇索博日嘎嘎查',
          },
          {
            ear_num: '58533',
            type: '红牛',
            price: '￥14500.00',
            addr1: '大板镇红旗嘎查',
            addr2: '索博日嘎镇索博日嘎嘎查',
          },
          {
            ear_num: '9082',
            type: '西门塔尔牛',
            price: '￥19500.00',
            addr1: '大板镇西哈嘎查',
            addr2: '索博日嘎镇索博日嘎嘎查',
          },
          {
            ear_num: '82278',
            type: '西门塔尔牛',
            price: '￥18500.00',
            addr1: '大板镇西哈嘎查',
            addr2: '索博日嘎镇索博日嘎嘎查',
          },
          {
            ear_num: '12632',
            type: '黑牛',
            price: '￥19500.00',
            addr1: '大板镇西哈嘎查',
            addr2: '索博日嘎镇索博日嘎嘎查',
          },
        ],

        maShineList: [
          {
            name: '打草机',
            use_year: '6年',
            price: '￥8000.00',
            addr1: '大板镇大冷村',
            addr2: '索博日嘎镇索博日嘎嘎查',
          },
          {
            name: '小型拖拉机',
            use_year: '4年',
            price: '￥12000.00',
            addr1: '大板镇西哈嘎查',
            addr2: '索博日嘎镇索博日嘎嘎查',
          },
          {
            name: '播种机',
            use_year: '5年',
            price: '￥2000.00',
            addr1: '大板镇西哈嘎查',
            addr2: '索博日嘎镇索博日嘎嘎查',
          },
          {
            name: '搅拌机草机',
            use_year: '3年',
            price: '￥4500.00',
            addr1: '大板镇红旗嘎查',
            addr2: '索博日嘎镇索博日嘎嘎查',
          }
        ],

        caoLiaoList: [
          {
            name: '干草',
            unit: '200捆',
            price: '￥4000.00',
            addr1: '大板镇大冷村',
            addr2: '索博日嘎镇索博日嘎嘎查',
          },
          {
            name: '豆饼',
            unit: '100袋',
            price: '￥12000.00',
            addr1: '大板镇西哈嘎查',
            addr2: '索博日嘎镇索博日嘎嘎查',
          },
          {
            name: '浓缩饲料',
            unit: '500袋',
            price: '￥2000.00',
            addr1: '大板镇西哈嘎查',
            addr2: '索博日嘎镇索博日嘎嘎查',
          },
          {
            name: '豆饼',
            use_year: '300袋',
            price: '￥4500.00',
            addr1: '大板镇红旗嘎查',
            addr2: '索博日嘎镇索博日嘎嘎查',
          }
        ],

        nongYongDiList: [
          {
            name: '1787',
            unit: '2000亩',
            price: '￥400000.00',
            addr1: '4年',
            addr2: '索博日嘎镇索博日嘎嘎查12号',
          },
          {
            name: '42343',
            unit: '1300亩',
            price: '￥320000.00',
            addr1: '大板镇西哈嘎查',
            addr2: '索博日嘎镇索博日嘎嘎查135号',
          },
          {
            name: '5344',
            unit: '120亩',
            price: '￥120000.00',
            addr1: '大板镇西哈嘎查',
            addr2: '索博日嘎镇索博日嘎嘎查672号',
          },
          {
            name: '84598',
            use_year: '1000亩',
            price: '￥12500.00',
            addr1: '大板镇红旗嘎查',
            addr2: '索博日嘎镇索博日嘎嘎查21号',
          }
        ],
      }
    },
    mounted() {
    },
    methods: {
      // 跳转到 menu 页面
      goToMenuPage() {
        this.$router.push({
          path: "/bigscreen/menuPage"
        })
      }
    }
  }
</script>

<style scoped lang="less">
  .activeColor { color: #2366fc !important; }

  .box_1 {
    width: 100%;
    height: 100%;
    padding: 10px;
    // background-image: url('../../../assets/images/ba-lin-you-qi/img2.png');
    display: block !important;

    .header {
      width: 100%;
      height: 40px;
      line-height: 40px;
      text-align: center;

      .text {
        width: 50%;
        height: 40px;
        font-size: 22px;
        font-weight: bold;
        color: #fff;
        // background-color: #2366fc;
        margin: 0 auto;
        cursor: pointer;
      }
    }

    .content_box_order {
      width: 100%;
      height: calc(100% - 50px);
      margin-top: 10px;
      padding: 10px;
      // border: 1px solid #f00;

      .content_item { 
        width: 100%;
        height: 50%;
        margin-bottom: 20px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        // border: 1px solid #f00;

        .order_box {
          width: 49%;
          height: 100%;

          .title { 
            width: 100%;
            text-align: left;
            position: relative;
            margin-bottom: 10px;
            
            span {
              font-weight: bold;
              font-size: 18px;
              position: absolute;
              bottom: 0;
              left: 50%;
              transform: translateX(-50%);
            }
          }

          .list_header {
            width: 100%;
            height: 30px;
            line-height: 30px;
            font-size: 20px;
            padding: 0 20px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            // color: #2366fc;
            color: rgb(13, 246, 94);

            // .num { width: 80px; }
            // .ear_num { width: 4%; }
            // .type { width: 8%; }
            // .price { width: 5%; }
            // .addr { width: 30%; }
          }

          .list_box {
            width: 100%;
            height: calc(100% - 50px);
            overflow: auto;
            margin-top: 10px;

            .list_item {
              width: 100%;
              font-size: 18px;
              padding: 10px;
              border: 1px solid #fff;
              border-radius: 10px;
              margin-bottom: 10px;
              
              
              // .num { width: 4%; }
              // .ear_num { width: 4%; }
              // .type { width: 8%; }
              // .price { width: 5%; }
              // .addr { width: 30%; }
            }
          }
          .list_box::-webkit-scrollbar {
            width: 0px;
            height: 100%;
            background-color: #f5f5f5;
          }

          ::-webkit-scrollbar-thumb {                 // 表示纵向滚动块
            border-radius: 10px;
            background: #2366fc;
          }
        }
      }
    }

  }
</style>